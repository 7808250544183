:root {
  --menu-width: 100px;
}

.AppContainer {
  background: var(--blue-bgl);
  position: relative;
}

.content {
  width: 100%;
  height: 100%;
  transition: all 0.2s linear 0.2s;
  padding-right: calc(var(--menu-width) * 3);
  padding-left: 0px;
}

.content.p-timeline {
  padding-left: calc(var(--menu-width) / 2 * 2);
  padding-right: calc(var(--menu-width) / 2 * 2);
}

.content.p-map {
  padding-left: calc(var(--menu-width) / 2 * 3);
  padding-right: calc(var(--menu-width) / 2 * 1);
}

.content.p-themes {
  padding-left: calc(var(--menu-width) / 2 * 4);
  padding-right: calc(var(--menu-width) / 2 * 0);
}

.menu-container {
  position: fixed;
  display: flex;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  justify-content: flex-end;
  pointer-events: none;
  z-index: 999;
}

.menu-bar {
  display: flex;
  transition: all 0.2s;
  pointer-events: all;
}

.menu-bar.timeline {
  background-color: var(--timeline-bgl);
}

.menu-bar.map {
  background-color: var(--map-bgl);
}

.menu-bar.themes {
  background-color: var(--themes-bgl);
}

.menu-bar-home {
  flex: 0 0 50px;
  margin-right: auto;
  transform: translateX(-100%);
  transition: all 0.2s;
  pointer-events: all;
  background-color: var(--blue-bgl);
  display: flex;
}

.menu-container.pages .menu-bar-home {
  transform: translateX(0%);
}

.menu-bar-title-home {
  display: flex;
  flex-direction: column;
  width: calc(var(--menu-width) / 2);
}

.menu-bar-title {
  width: var(--menu-width);
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.title-container {
  flex: 0 0 50%;
  justify-content: center;
  display: flex;
  padding-bottom: 1rem;
  transition: padding-bottom 0.35s ease-out;
}

.title-container.over {
  padding-bottom: 2rem;
}

.title-container p {
  font-family: "GT America Expanded", sans-serif;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  font-size: 1.2rem;
}

.menu-bar-title-home .title-container p {
  font-weight: 500;
}

.expand-container {
  flex: 0 0 50%;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.expand-container-home {
  flex: 0 0 50%;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.expand-container-home a {
  color: white;
  text-decoration: none;
}

.about-button {
  width: calc(var(--menu-width) / 2);
  height: calc(var(--menu-width) / 2);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.about-button a {
  color: var(--blue-bgl);
  text-decoration: none;
}

.expand-container i {
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 1s;
}

.open .expand-container i {
  transform: rotate(-45deg);
}

.menu-bar-desc {
  overflow: hidden;
  width: 0px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  max-width: 0px;
}

.desc-container {
  flex: 0 0 50%;
  display: flex;
  align-items: flex-end;
  width: 400px;
}

.desc-container p {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.desc-link-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 1rem;
  flex: 0 0 50%;
  display: flex;
  align-items: flex-start;
  width: 400px;
}

.desc-link {
  width: 100%;
  margin: 0;
  padding: 0 2rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  font-family: "GT America Expanded", sans-serif;
  font-size: 1.2rem;
  transition: padding 0.35s ease-out;
}

.desc-link:hover {
  text-decoration: none;
  color: white;
  padding: 0 1rem 0 1rem;
}

.menu-bar.open .menu-bar-desc {
  width: 400px;
  max-width: 400px;
}

.menu-page-content {
  width: 0%;
  transition: background-color 0.2s linear 0.6s, width 0.2s;
  pointer-events: none;
  position: relative;
  background-color: var(--blue-bgl);
  overflow: hidden;
}

.menu-page-content.open {
  width: 100%;
  background-color: transparent;
}

.menu-container.pages .menu-bar-title {
  width: calc(var(--menu-width) / 2);
}
