.container {
  height: var(--menu-height);
  overflow-x: scroll;
  border-top: solid var(--timeline-bgl) 1px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
}

.decade {
  padding: 0 20px;
  justify-content: center;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: "GT America Expanded", sans-serif;
  font-size: 0.875rem;
  background-color: transparent;
  transition: background-color 0.35s ease-out;
  min-width: 90px;
  max-width: 90px;
}

.decade.current {
  background-color: var(--timeline-bgl);
}
