.textContent {
  position: relative;
  p {
    font-size: 1rem;
  }
  h2 {
    font-size: 1rem;
    font-family: "GT America Expanded", sans-serif;
  }

  h3 {
    font-family: "GT America Expanded", sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 0.875rem;
  }

  a {
    color: var(--themes-bgl);
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.35s ease-out;
  }

  a:hover {
    border-bottom: 1px solid var(--themes-bgl);
  }

  blockquote {
    text-align: left;
    font-family: "GT America Expanded", sans-serif;
    font-weight: 500;
    // text-transform: uppercase;
  }

  blockquote + p {
    font-family: "GT America Expanded", sans-serif;
    text-transform: uppercase;
    font-size: 0.875rem;
    text-align: left;
  }

  blockquote + p:before {
    content: "→ ";
  }
}

.textQuotation {
  display: block;
}

.objectContainerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // min-height: 50vh;
  // max-height: 70vh;
  // height: 600px;
}

.objectContainer {
  margin: auto;
  width: auto;
  max-width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
}

.objectContent {
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 0;
  position: relative;
  overflow: hidden;
}

.openImageButton {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(calc(-100% - 5px));
  transition: transform 0.35s ease-out;
  a {
    color: white;
    text-decoration: none;
    i {
      display: flex;
    }
  }
}

.selected-image-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 10px 10px 0px 10px;
  transform: translateY(100%);
  transition: transform 0.35s ease-out;
}

.slickImageContainer {
  position: relative;
  overflow: hidden;
}

.objectContent:hover .selected-image-info,
.slickImageContainer:hover .selected-image-info {
  transform: translateY(-0%);
}

.objectContent:hover .openImageButton,
.slickImageContainer:hover .openImageButton {
  transform: translateY(0%);
}

.selected-image-title {
  font-family: "GT America Expanded", sans-serif;
  text-transform: uppercase;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  color: white;
}

.selected-image-provenance {
  font-size: 0.69rem;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0.5rem;
}

.caption {
  flex-grow: 1;
  width: 0;
  position: relative;
  flex: 1 1 auto;
}

.captionText {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  overflow: hidden;
  background: white;
  p {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-family: "GT America Expanded", sans-serif;
    white-space: nowrap;
    position: relative;
    overflow-x: auto;
    margin-bottom: 0;
    padding: 15px 5px;
  }
}

.captionText::after {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  content: "";
  width: 30px;
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0, #fff);
}

.captionWrapper {
  display: flex;
}

.objectContentImage {
  object-fit: contain;
  width: auto;
  max-width: 100%;

  max-height: calc(100vh - (var(--menu-height) * 2) - 30px);
  height: 350px;
}

.objectContentImage.goBig {
  width: 100% !important;
  object-fit: cover !important;
}

.back-arrow,
.forward-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 5px;
  opacity: 1;
  transition: opacity 0.35s ease-out;
  pointer-events: none;
  i {
    background-color: white;
    border-radius: 999px;
    padding: 2px;
    pointer-events: all;
    cursor: pointer;
  }
}

.back-arrow.arrow-disabled,
.forward-arrow.arrow-disabled {
  opacity: 0;
}

.back-arrow {
  left: 0;
}
.forward-arrow {
  right: 0;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.videoMarginResponsive {
  margin: inherit !important;
}

.audioContainer {
  display: flex;
  flex-direction: column;
}

.objectContentAudio {
  width: 100% !important;
}

.audioTitle {
  margin-left: auto;
  font-family: "GT America Expanded", sans-serif;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.audioControlButton {
  padding: 5px;
  margin-right: 5px;
  border: 1px solid black;
  cursor: pointer;
  i {
    display: flex;
  }
}

.mapcontainer {
  position: relative;
  height: 350px;
  max-height: calc(100vh - (var(--menu-height) * 2) - 30px);
}

.marker {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--blue-bgl);
}

.clustermarker {
  border-radius: 50%;
  background-color: var(--blue-bgl);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  font-family: "GT America Expanded", sans-serif;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.zoomcontrol button {
  background-color: var(--green-map) !important;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .textContent {
    p {
      font-size: 1.3rem;
    }
    h2 {
      font-size: 1.3rem;
    }

    h3 {
      font-size: 0.875rem;
    }

    .textQuotation {
      display: flex;
    }

    blockquote {
      flex: 0 0 60%;
      font-size: 1.2rem;
      p {
        font-size: 1.3rem;
      }
    }

    blockquote + p {
      align-self: flex-start;
      text-align: left;
      margin: 0;
      padding-left: 30px;
      font-size: 0.875rem;
      position: sticky;
      top: 90px;
    }
  }

  .objectContentImage {
    // min-height: 50vh;
    // max-height: 70vh;
    max-height: 70vh;
    height: auto;
  }

  .mapcontainer {
    height: calc(100vh - 150px);
  }

  .objectContentImage.goBig {
    height: calc(100vh - 150px);
    max-height: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
