.eventPanel {
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: all 0.35s ease-out;
  pointer-events: all;
  background-color: var(--blue-bgl);
  color: #fff;

  &.open {
    transform: translateX(0%);
  }

  .selectedEventContent {
    height: 100%;
    background-color: var(--blue-bgl);
    padding: 0 15px 15px 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    overflow-y: auto;

    .detail-top {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: var(--blue-bgl);
      padding: 15px 0;

      i {
        cursor: pointer;
      }
    }
    .detail-category {
      font-family: "GT America Expanded", sans-serif;
      font-weight: normal;
      text-transform: uppercase;
      margin: 0;
      font-size: 0.875rem;
    }

    .selected-image-container {
      position: relative;
      overflow: hidden;
      margin-bottom: 15px;
    }

    .selected-image-info {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      padding: 10px 10px 0px 10px;
      transform: translateY(100%);
      transition: transform 0.35s ease-out;
    }

    .selected-image-container:hover .selected-image-info {
      transform: translateY(-0%);
    }

    .selected-image-container:hover div[openimagebutton] {
      transform: translateY(0%);
    }

    .selectedEventImage {
      width: 100%;
      height: auto;
    }

    .selected-image-title {
      font-family: "GT America Expanded", sans-serif;
      text-transform: uppercase;
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }

    .selected-image-provenance {
      font-size: 0.69rem;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 0.5rem;
    }

    .detail-date {
      font-family: "GT America Expanded", sans-serif;
      color: rgba(255, 255, 255, 0.5);
      font-size: 0.75rem;
    }

    .detail-title {
      font-family: "GT America Expanded", sans-serif;
      margin: 15px 0;
      text-transform: uppercase;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .eventPanel {
    width: 380px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
