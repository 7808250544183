:root {
  --green-map: #0d9968;
}

.ganttcontainer {
  height: 30px;
  background-color: var(--blue-bgl);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  transition: height 0.35s ease-out;
  border-top: 1px solid var(--green-map);
  &.open {
    height: 40%;
  }
}

.ganttheader {
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid var(--green-map);

  .ganttButton {
    min-width: 0;
    h6 {
      margin: 0;
      font-family: "GT America Expanded", sans-serif;
      text-transform: uppercase;
      font-size: 0.875rem;
    }

    i {
      color: var(--green-map);
      margin-right: 15px;
    }
  }

  .legendColors {
    margin-left: 20px;
    display: flex;
    border-left: 1px solid var(--green-map);
    height: 100%;
  }

  .legend {
    display: flex;
    align-items: center;
    margin-left: 10px;

    i {
      font-size: 1.3rem !important;
    }

    .closed {
      color: #c9395a;
    }
  }
}

.ganttcontent {
  flex: 1;
  overflow-y: auto;
}

.ganttLine {
  fill: white;
}

.tickLine {
  stroke: rgba(255, 255, 255, 0.25);
  stroke-width: 1;
  stroke-dasharray: 3, 3;
}

.tickCurrent {
  stroke: magenta;
  stroke-width: 1;
  stroke-dasharray: 3, 3;
}

.rowBlack {
  background-color: black;
}

.rowDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.title {
  margin: 0;
  font-weight: normal;
  cursor: pointer;
  transform: translateX(0);
  transition: transform 0.35s ease-out;
}

.title:hover {
  transform: translateX(10px);
}
