.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #fff;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-bgl);
  padding: 15px;
}

.question {
  font-size: 10rem;
  font-family: "GT America Expanded", sans-serif;
  font-weight: 500;
  -webkit-text-stroke: 1px white;
  color: transparent;
}

.link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-family: "GT America Expanded", sans-serif;
  text-transform: uppercase;
  margin-top: 25px;

  i {
    transition: padding-left 0.35s ease-out;
    padding-left: 10px;
  }
}

.link:hover {
  color: white;
  text-decoration: none;

  i {
    padding-left: 15px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .question {
    font-size: 15rem;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
