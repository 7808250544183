.mapcontainer {
  flex: 1;
  min-height: 0;
  width: 100%;
  position: relative;
}

.regions {
  position: absolute;
  height: auto;
  top: 0;
  right: 0;
  z-index: 4;

  display: flex;
  flex-direction: row;
  transition: all 0.5s ease-out;
  overflow-x: auto;
  max-width: 100%;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.marker {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--blue-bgl);
}

.clustermarker {
  border-radius: 50%;
  background-color: var(--blue-bgl);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  font-family: "GT America Expanded", sans-serif;
  svg {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.overlay {
  width: auto;
  height: 100%;
  display: flex;
  pointer-events: none;
  z-index: 4;
  position: absolute;
  top: 0;
}

.closed {
  color: #c9395a;
}

.resetZoom {
  position: absolute;
  bottom: 88px;
  right: 11px;
  z-index: 10;
  width: 26px;
  height: 26px;
  border-radius: 2px;
  background-color: var(--green-map);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
  i {
    font-size: 1.3rem;
  }
}

.zoomcontrol {
  bottom: 30px !important;
}
.zoomcontrol button {
  background-color: var(--green-map) !important;
}
