.regionSelector {
  padding: 10px;

  .region {
    width: 110px;
    height: 80px;
    border: solid rgba(255, 255, 255, 0.5) 1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 5px;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    transition: border 0.35s ease-out;
  }

  .region:hover {
    border: solid var(--green-map) 1px;
  }
}

.label {
  text-transform: uppercase;
  font-size: 0.75rem;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .regionSelector {
    .region {
      width: 150px;
      height: 100px;
    }
  }
  .label {
    font-size: 1rem;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
