.container-themes {
  position: relative;
  height: 100%;
  background-color: var(--blue-bgl);
}
.container-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
}

.container-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.35s ease-out;
  background-color: var(--themes-bgl);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(1);
    mix-blend-mode: color-burn;
  }
}

.container-background-opacity {
  opacity: 0.4;
}

.header {
  height: 50px;
  align-items: stretch;
  font-family: "GT America Expanded", sans-serif;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);

  .col-grid {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
}

.line {
  height: 100px;
  align-items: stretch;
  background-color: transparent;
  transition: background-color 0.35s ease-out;
  font-family: "GT America Expanded", sans-serif;
  font-size: 1.3rem;

  i {
    transform: translateX(0);
    transition: transform 0.35s ease-out;
  }

  h3 {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 0;
  }
}

.line:hover {
  background-color: rgba(255, 255, 255, 0.4);

  i {
    transform: translateX(70%);
  }
}

.col-grid {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: white;
    text-decoration: none;
  }
}

.col-grid:last-of-type {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

//fix for EDGE
@supports not (mix-blend-mode: color-burn) {
  .container-background::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(15, 35, 191, 0.65);
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .line {
    height: 150px;
    h3 {
      font-size: 1.75rem;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
