.themeCover {
  height: 100%;
  background-color: var(--themes-bgl);
  background-blend-mode: color-burn;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15px 0px;
  display: flex;

  h5 {
    font-family: "GT America Expanded", sans-serif;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.875rem;
    text-align: center;
  }

  h1 {
    font-family: "GT America Expanded", sans-serif;
    text-transform: uppercase;
    font-size: 3.2rem;
    -webkit-text-stroke: 2px white;
    color: transparent;
    margin: 25px 0 40px 0;
    text-align: center;
  }

  p {
    font-size: 1.1rem;
  }
}

//fix for EDGE
@supports not (mix-blend-mode: color-burn) {
  .themeCover div {
    z-index: 1;
  }

  .themeCover::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(15, 35, 191, 0.65);
    z-index: 0;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .themeCover {
    height: 100vh;
    h5 {
      font-size: 1.5rem;
    }
    h1 {
      font-size: 4.875rem;
      margin: 30px 0 80px 0;
    }
    p {
      font-size: 1.5rem;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
