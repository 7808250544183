@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: "GT America";
  src: url("./fonts/GT-America-Standard-Regular.woff2") format("woff2"),
    url("./fonts/GT-America-Standard-Regular.woff") format("woff"),
    url("./fonts/GT-America-Standard-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GT America";
  src: url("./fonts/GT-America-Standard-Regular-Italic.woff2") format("woff2"),
    url("./fonts/GT-America-Standard-Regular-Italic.woff") format("woff"),
    url("./fonts/GT-America-Standard-Regular-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "GT America";
  src: url("./fonts/GT-America-Standard-Bold.woff2") format("woff2"),
    url("./fonts/GT-America-Standard-Bold.woff") format("woff"),
    url("./fonts/GT-America-Standard-Bold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GT America";
  src: url("./fonts/GT-America-Standard-Bold-Italic.woff2") format("woff2"),
    url("./fonts/GT-America-Standard-Bold-Italic.woff") format("woff"),
    url("./fonts/GT-America-Standard-Bold-Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "GT America Expanded";
  src: url("./fonts/GT-America-Expanded-Black.woff2") format("woff2"),
    url("./fonts/GT-America-Expanded-Black.woff") format("woff"),
    url("./fonts/GT-America-Expanded-Black.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GT America Expanded";
  src: url("./fonts/GT-America-Expanded-Regular.woff2") format("woff2"),
    url("./fonts/GT-America-Expanded-Regular.woff") format("woff"),
    url("./fonts/GT-America-Expanded-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("./fonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("./fonts/MaterialIcons-Regular.woff") format("woff"),
    url("./fonts/MaterialIcons-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "map";
  src: url("./fonts/map.eot?54874211");
  src: url("./fonts/map.eot?54874211#iefix") format("embedded-opentype"),
    url("./fonts/map.woff2?54874211") format("woff2"),
    url("./fonts/map.woff?54874211") format("woff"),
    url("./fonts/map.ttf?54874211") format("truetype"),
    url("./fonts/map.svg?54874211#map") format("svg");
  font-weight: normal;
  font-style: normal;
}

@import "variables";
@import "~bootstrap/scss/bootstrap";

:root {
  --timeline-bgl: #0f23bf;
  --map-bgl: #2a69d1;
  --themes-bgl: #288ec7;
  --blue-bgl: #00041b;
  --blue-bgl-rgb: 0, 4, 27;
  --black-body: #212529;
  --module-padding: 25px;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "map";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-branch:before {
  content: "\e800";
}
.icon-headquarter:before {
  content: "\e801";
}
.icon-office:before {
  content: "\e802";
}

.mapboxgl-popup-content {
  padding: 0 !important;
  border-radius: 0 !important;
}

.mapboxgl-popup-tip {
  display: none !important;
}

.slider.variable-width {
  .slick-slide img {
    opacity: 0.75;
    filter: grayscale(1);
    transition: all 0.5s ease-out;
  }
  .slick-slide.slick-active.slick-current img {
    filter: grayscale(0);
    opacity: 1;
  }
}

.slick-slide {
  z-index: 0;
}

.slick-slide.slick-active.slick-current {
  z-index: 1;
}

.hyphens {
  hyphens: auto;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  :root {
    --module-padding: 50px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
