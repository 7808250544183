.navigationcontainer {
  border-top: 1px solid var(--green-map);
  height: 31px;
  flex-grow: 0;
  flex-shrink: 0;
}

.navigationcontainer svg {
  cursor: pointer;
  background-color: black;
}

.legend {
  font-family: "GT America Expanded", sans-serif;
  font-weight: 500;
}
.legend-container {
  border-right: 1px solid var(--green-map);
}

.cursor {
  fill: transparent;
  stroke: magenta;
  stroke-width: 1px;
  cursor: ew-resize;
}

.cursor-bar {
  fill: magenta;
  cursor: ew-resize;
}

.tick {
  pointer-events: none;
  text-anchor: middle;
  fill: #fff;
  font-size: 0.69rem;
  font-family: "GT America Expanded", sans-serif;
}
