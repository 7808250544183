.container {
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.container-timeline {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.container-images {
  cursor: grab;
  position: absolute;
  height: 100px;
  top: 13px;
}

.container-images:active {
  cursor: grabbing;
}

.container-timeline-utility {
  width: 100%;
  height: 100%;
  position: relative;
}

.container svg {
  cursor: grab;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.container svg:active {
  cursor: grabbing;
}

.eventContainer {
  cursor: pointer;
  transition: opacity 0.5s ease-out;
  //pointer-events: bounding-box;

  text,
  line {
    pointer-events: none;
  }

  &.faded {
    opacity: 0.25;
  }

  .eventLine {
    stroke: #fff;
    stroke-width: 2px;
  }

  .eventPoint {
    stroke: none;
    fill: #fff;
    stroke: #000;
    stroke-width: 2px;
  }

  .eventDate {
    opacity: 0.5;
    font-size: 0.69rem;
    fill: #fff;
  }

  .eventTitle {
    fill: #fff;
    font-size: 0.75rem;
  }
}

.tick {
  text-anchor: middle;
  fill: #fff;
  font-size: 0.69rem;
  font-family: "GT America Expanded", sans-serif;
  opacity: 0.5;
}

.grid {
  stroke: rgba(255, 255, 255, 0.25);
  stroke-dasharray: 3, 3;
}

.categoryLabel {
  text-transform: uppercase;
  fill: #fff;
  font-family: "GT America Expanded", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  transition: opacity 0.5s ease-out;
  opacity: 1;
  &.faded {
    opacity: 0.25;
  }
}

.historyRow {
  fill: #0b1030;
}

.socioEconomicRow {
  fill: #000000;
}

.politicalRow {
  fill: #00031b;
}

.eventImage {
  height: 100px;
  width: auto;
  position: absolute;
  transition: all 0.5s ease-out;
  cursor: pointer;
  z-index: 0;

  &.faded {
    opacity: 0.25;
  }

  &.highlight {
    height: 150px;
  }
}

.eventImage:hover {
  z-index: 1;
}

.eventImageHighlight {
  height: 200px;
  width: auto;
}

.overlay {
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: none;
  z-index: 1;
  position: absolute;
  top: 0;
  background-color: rgba(var(--blue-bgl-rgb), 0);
  transition: background-color 0.35s ease-out;
  &.open {
    background-color: rgba(var(--blue-bgl-rgb), 0.75);
  }
}

.backdrop {
  flex: 1;
  height: 100%;
  background-color: transparent;
  pointer-events: all;
}
