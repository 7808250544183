.mapcontainer {
  flex: 1;
  min-height: 0;
  width: 100%;
  position: relative;
}

.marker {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--blue-bgl);
}

.clustermarker {
  border-radius: 50%;
  background-color: var(--blue-bgl);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "GT America Expanded", sans-serif;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.overlay {
  width: 100%;
  display: flex;
  pointer-events: none;
  z-index: 11;
  position: fixed;
  top: var(--menu-height);
  bottom: 0;
}

.regions {
  position: absolute;
  height: auto;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 4;
  display: flex;
  flex-direction: row;
  transition: all 0.5s ease-out;
  overflow-x: auto;
  font-size: 0.875rem;
}

.closed {
  color: #c9395a;
}

.zoomcontrol button {
  background-color: var(--green-map) !important;
}
