.background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow-x: hidden;
}

.image_1 {
  background-image: linear-gradient(
      to right,
      rgba(0, 4, 27, 0.7),
      rgba(0, 4, 27, 0.7)
    ),
    url(./imgs/home_1.jpg);
}

.image_2 {
  background-image: linear-gradient(
      to right,
      rgba(0, 4, 27, 0.7),
      rgba(0, 4, 27, 0.7)
    ),
    url(./imgs/home_2.jpg);
}

.image_3 {
  background-image: linear-gradient(
      to right,
      rgba(0, 4, 27, 0.7),
      rgba(0, 4, 27, 0.7)
    ),
    url(./imgs/home_3.jpg);
}

.image_4 {
  background-image: linear-gradient(
      to right,
      rgba(0, 4, 27, 0.7),
      rgba(0, 4, 27, 0.7)
    ),
    url(./imgs/home_4.jpg);
}

.image_5 {
  background-image: linear-gradient(
      to right,
      rgba(0, 4, 27, 0.7),
      rgba(0, 4, 27, 0.7)
    ),
    url(./imgs/home_5.jpg);
}

.grid {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.border {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.border-first {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.content {
  overflow-y: auto;
}

.title {
  font-size: 2.4rem;
  line-height: 1.1;
  text-transform: uppercase;
  font-family: "GT America Expanded", sans-serif;
  font-weight: 500;
  -webkit-text-stroke: 1px white;
  color: transparent;
  margin-left: -0.2rem;
  margin-top: 15px;
  margin-bottom: 15px;
}

.title::before {
  content: "BGL";
  color: white;
  -webkit-text-stroke: none;
}

.description {
  font-size: 0.875rem;
}

.read-more {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  text-decoration: none;
  font-family: "GT America Expanded", sans-serif;
  color: white;
  text-transform: uppercase;
  padding: 0;
  transition: padding 0.35s ease-out;
}

.read-more:hover {
  text-decoration: none;
  padding: 0;
}

.logo-img {
  filter: grayscale(1);
  transition: filter 0.35s ease-out;
}

.logo-img:hover {
  filter: grayscale(0);
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .title {
    font-size: 3.1rem;
    line-height: 1;
    -webkit-text-stroke: 2px white;
    margin-top: 0px;
    margin-left: -0.5rem;
  }

  .description {
    font-size: 1rem;
    column-count: 2;
  }

  .read-more {
    padding: 0 1rem 0 0;
  }

  .read-more {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .title {
    font-size: 5.635rem;
  }
}
