.container {
  height: var(--menu-height);
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 333;
  background-color: rgba(255, 255, 255, 1);
  border-top: solid var(--themes-bgl) 1px;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0px;
  color: var(--black-body);
  transition: background-color 0.35s ease-out, color 0.35s ease-out;
  &.negative {
    background-color: rgba(255, 255, 255, 1);
  }
}

.chapter {
  padding: 15px;
  font-family: "GT America Expanded", sans-serif;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid var(--themes-bgl) 1px;
}

.chapter:last-of-type {
  border-right: none;
}

.chapter.active {
  background-color: var(--themes-bgl);
  color: white;
}

.negative {
}
