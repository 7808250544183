.wrapper {
  flex: 1;
  width: 100%;
  position: relative;
}

.container {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.eventRow {
  flex: 1;
  position: relative;
  width: 100%;
}

.eventGroupContainer {
  cursor: pointer;
  pointer-events: bounding-box;
  position: absolute;
  bottom: 0px;
  top: 0px;
  display: flex;

  .eventContainer {
    overflow: hidden;
    padding: 30px 10px 10px 10px;
  }

  .event {
    border-top: 1px solid white;
    padding-top: 5px;
  }

  .event-date {
    opacity: 0.5;
    font-size: 0.69rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .event-date i {
    font-size: 1rem !important;
  }

  .event-title {
    font-size: 0.875rem;
  }
}

.ticksContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
}

.tick {
  position: absolute;
  bottom: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
}

.line {
  flex: 1;
  width: 0px;
  border-left: rgba(255, 255, 255, 0.5) dashed 1px;
}

.label {
  flex-grow: 0;
  flex-shrink: 0;
  font-family: "GT America Expanded", sans-serif;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  padding: 5px 0px;
  transform: translateX(-50%);
}

.category-bg-container {
  position: absolute;
  left: 0px;
  top: 0;
  right: 0;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.category-label-container {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.category-label {
  flex: 1;
  text-transform: uppercase;
  font-family: "GT America Expanded", sans-serif;
  font-weight: 500;
  font-size: 0.69rem;
  padding-top: 5px;
}

.historyofthebankRow {
  background-color: #0b1030;
  flex: 1;
}

.socio-economicRow {
  background-color: #000000;
  flex: 1;
}

.politicalRow {
  background-color: #00031b;
  flex: 1;
}

.eventImage {
  height: 100px;
}

.overlay {
  width: 100%;
  display: flex;
  pointer-events: none;
  z-index: 1;
  position: fixed;
  top: var(--menu-height);
  bottom: 0;
}

.eventPanel {
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: all 0.2s linear;
  pointer-events: all;
  background-color: #00031b;
  color: #fff;

  &.open {
    transform: translateX(0%);
  }

  .selectedEventContent {
    height: 100%;
    background-color: black;
    padding: 20px;
    overflow-y: auto;

    .selectedEventImage {
      width: 100%;
      height: auto;
    }
  }
}
