.chapter {
  color: var(--black-body);
  padding: 50px 0px;
  background-color: white;

  h6 {
    font-family: "GT America Expanded", sans-serif;
    text-transform: uppercase;
    margin-top: 15px;
    font-size: 0.875rem;
  }

  h1 {
    font-family: "GT America Expanded", sans-serif;
    text-transform: uppercase;
    font-size: 2.5rem;
    -webkit-text-stroke: 1px black;
    color: transparent;
  }
}

.opacity-0 {
  opacity: 0;
  transition: opacity 2s ease-out;
}

.opacity-1 {
  opacity: 1;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .chapter {
    h6 {
      font-size: 1rem;
    }

    h1 {
      font-size: 4.875rem;
      -webkit-text-stroke: 2px black;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  h1 {
    hyphens: none !important;
  }
}
