:root {
  --menu-height: 65px;
  --pad-menu-x: 65px;
}

.AppContainerMobile {
  background: var(--blue-bgl);
  position: relative;
}

.top-bar {
  height: var(--menu-height);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1003;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  pointer-events: none;
}

.toggle-menu {
  color: white;
  pointer-events: all;
  margin-left: auto;
  cursor: pointer;
}

.content {
  width: 100%;
  height: 100%;
  // padding-top: var(--menu-height);
  padding-top: 0;
}

.content.p-home {
  padding-top: 0;
  padding-bottom: calc(var(--menu-height) * 3);
}

.content.p-timeline {
  padding-top: var(--menu-height);
}

.content.p-map {
  padding-top: var(--menu-height);
}

.content.p-themes {
  padding-top: var(--menu-height);
}

.toggle-menu i {
  transform: rotate(90deg);
}

.menu-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: var(--menu-height);
  bottom: 0px;
  left: 0px;
  right: 0px;
  justify-content: flex-end;
  pointer-events: none;
}

.menu-bar {
  display: flex;
  transition: all 0.2s;
  pointer-events: all;
  flex-direction: column;
  width: 100%;
  flex: 0;
  min-height: var(--menu-height);
}

.menu-bar.open {
  flex: 1;
}

.menu-bar-title {
  height: var(--menu-height);
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.menu-bar-desc {
  overflow: hidden;
  height: 0px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
}

.menu-bar.open .menu-bar-desc {
  height: calc(100vh - var(--menu-height) * 4); //not so nice
}

.title-container p {
  font-family: "GT America Expanded", sans-serif;
  margin: 0;
}

.expand-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.expand-container i {
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 1s;
}

.open .expand-container i {
  transform: rotate(-45deg);
}

.desc-container {
  padding: 30px;
}

.desc-container p {
  font-size: 1.2rem;
}

.desc-link-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 15px 30px;
  display: flex;
  margin-top: auto;
}

.desc-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  font-family: "GT America Expanded", sans-serif;
}

.desc-link:hover {
  text-decoration: none;
  color: white;
}

.menu-mobile {
  position: fixed;
  padding-top: var(--menu-height);
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  background-color: var(--blue-bgl);
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.4s;
}

.menu-mobile.open {
  transform: translateX(0%);
}

.menu-mobile-link {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 15px 30px;
}

.timeline-bg {
  background-color: var(--timeline-bgl);
}

.map-bg {
  background-color: var(--map-bgl);
}

.themes-bg {
  background-color: var(--themes-bgl);
}

.title {
  font-family: "GT America Expanded", sans-serif;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  a {
    color: white;
    text-decoration: none;
  }

  i {
    pointer-events: all;
  }
}
