.openImageButton {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(calc(-100% - 5px));
  transition: transform 0.35s ease-out;
  a {
    color: white;
    text-decoration: none;
    i {
      display: flex;
    }
  }
}
