.container {
  height: 100px;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: row;
}

.labels {
  .label {
    font-family: "GT America Expanded", sans-serif;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 0.69rem;
    padding: 4px 8px;
  }
  .legend {
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 0.69rem;
    padding: 4px 8px;
    color: rgba(255, 255, 255, 0.5);
    font-family: "GT America", sans-serif;
  }

  .gradient {
    background-image: linear-gradient(
      to right,
      rgba(120, 120, 120, 1) 10%,
      rgba(255, 255, 255, 1) 100%
    );
    height: calc(100% - 10px);
    margin: 0px 4px;
  }
}

.svg {
  height: 100%;
  flex: 1;
  cursor: pointer;
  .historyRow {
    fill: #0b1030;
  }

  .socioEconomicRow {
    fill: #000000;
  }

  .politicalRow {
    fill: #00031b;
  }
}

.cursor {
  fill: transparent;
  stroke: magenta;
  stroke-width: 1px;
  cursor: ew-resize;
}

.cursor-bar {
  fill: magenta;
  cursor: ew-resize;
}

.event {
  fill: rgba(120, 120, 120, 1);
  mix-blend-mode: screen;
}

.tick {
  text-anchor: middle;
  fill: #fff;
  font-size: 0.69rem;
  font-family: "GT America Expanded", sans-serif;
}
