.background {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.grid {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  pointer-events: none;
}

.content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.row-sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.border {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.border-first {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.title {
  font-size: 3.5rem;
  line-height: 1;
  text-transform: uppercase;
  font-family: "GT America Expanded", sans-serif;
  font-weight: 500;
  -webkit-text-stroke: 1px white;
  color: var(--blue-bgl);
  margin-left: -0.2rem;
}

.read-more {
  text-decoration: none;
  font-family: "GT America Expanded", sans-serif;
  color: white;
  text-transform: uppercase;
  margin: 17px 0px;
}

.read-more .back {
  transform: translateX(0);
  transition: transform 0.35s ease-out;
}

.read-more:hover {
  text-decoration: none;
}

.read-more:hover .back {
  transform: translateX(-15px);
}

.description {
  h6 {
    text-transform: uppercase;
    font-size: 0.75rem;
    margin-bottom: 2px;
    margin-top: 20px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .title {
    font-size: 6.5rem;
    line-height: 1;
    -webkit-text-stroke: 2px white;
    margin-left: -0.5rem;
  }

  .description {
    font-size: 1.3rem;
  }

  .read-more {
    margin: 0px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
