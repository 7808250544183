.tooltip {
  width: 200px;
  height: 200px;
  background-position: center center;
  background-size: cover;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px;
}

.tooltip.noimage {
  height: auto !important;
  background-color: var(--blue-bgl);
}

.description {
  margin-top: auto;
  text-transform: uppercase;
  font-size: 0.69rem;
  color: rgba(255, 255, 255, 0.5);
  font-family: "GT America Expanded", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    color: white;
    cursor: pointer;
    font-size: 1rem;
  }
}

.title {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-family: "GT America Expanded", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: white;
}
