.chaptersMap {
  z-index: 333;
  background-color: transparent;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0px;
  color: white;
  transition: background-color 0.35s ease-out, color 0.35s ease-out;
  padding-top: 30px;
  &.negative {
    background-color: rgba(255, 255, 255, 1);
  }
}

.points-container {
  display: flex;
  flex-direction: column;
}
.points {
  width: 10px;
  height: 10px;
  border-radius: 999px;
  background-color: white;
  cursor: pointer;
  transform: scale(1) translateX(-50%);
  transition: background-color 0.35s ease-out, transform 0.35s ease-out;
}

.points:hover {
  transform: scale(2) translateX(-25%);
}

.points-modules-line {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  justify-content: space-between;
}

.end-chapter {
  width: 5px;
  height: 10px;
  background-color: white;
}

.chapters {
  font-family: "GT America Expanded", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

.chapter-title {
  font-family: "GT America Expanded", sans-serif;
  opacity: 0;
  font-size: 0.69rem;
  padding: 3px;
  transition: opacity 0.35s ease-out;
}

.opacity-1 {
  opacity: 1;
}

.negative {
  color: var(--black-body) !important;
  .points,
  .end-chapter {
    background-color: black;
  }

  .points-modules-line {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .points-modules-line.active {
    background-color: var(--themes-bgl);
  }
}

.tooltip {
  font-family: "GT America Expanded", sans-serif;
  font-size: 0.875rem !important;
  border-radius: 0 !important;
}
