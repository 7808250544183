.loader {
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background: var(--blue-bgl);
  opacity: 0;
  position: absolute;
  z-index: 1002;
  pointer-events: none;
  transition: opacity 0.35s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  opacity: 1;
}
